<template>
  <div class="form">
    <div class="head">
      <div class="select">
        <select v-model="$i18n.locale">
          <option value="en">English</option>
          <!-- <option value="zh">中文</option> -->
          <option value="fr">France</option>
          <option value="de">Deutschland</option>
          <option value="it">Italiano</option>
          <option value="pt">Portugal</option>
          <option value="es">español</option>
        </select>
      </div>
      <div class="mask flex-column center">
        <img class="logo" src="/logo.png" alt="" />
        <p class="resgister">{{ $t("resgister") }}</p>
      </div>
    </div>

    <div class="formData">
      <div class="item orderNo">
        <div class="label flex center-a">
          <span>*</span>
          <p>{{ $t("orderNoLabel") }}</p>
        </div>
        <div class="label flex center-a">
          <!-- <img style="padding-left: 8px; width: 16px" src="/info.png" /> -->
          <p>
            <a style="padding-left: 12px; font-size: 6px;color:red;text-decoration: none;" href="https://www.amazon.com/gp/css/order-history" target="blank">
              {{ $t("orderWarm") }}</a>
          </p>
        </div>
        <div class="value">
          <input v-model="formData.orderNum" type="text" placeholder="eg:114-2879717-2117851" />
        </div>
      </div>
      <!-- <div class="bottom-box flex"> -->
      <div class="item">
        <div class="label flex center-a">
          <!-- <span>*</span> -->
          <p>{{ $t("userNameLabel") }}</p>
        </div>
        <div class="value">
          <input v-model="formData.userName" type="text" />
        </div>
      </div>
      <div class="item">
        <div class="label flex center-a">
          <!-- <span>*</span> -->
          <p>{{ $t("eMailLabel") }}</p>
        </div>
        <div class="value">
          <input v-model="formData.email" type="text" />
        </div>
      </div>
      <div class="item">
        <div class="label flex center-a">
          <!-- <span>*</span> -->
          <p>{{ $t("Phone") }}</p>
        </div>
        <div class="value">
          <input v-model="formData.phone" type="text" />
        </div>
      </div>
      <!-- </div> -->

      <!-- <h3>{{ $t("Lifetime Warranty Terms") }}</h3>
      <p>
        {{
          $t(
            "All Of our products come With a Lifetime Replacement Guarantee.For ANY quality-related issues,we Will send you a replacement for free!"
          )
        }}
      </p> -->
      <div class="flex center">
        <button class="pointer" :class="{
            disable:
              !this.formData.orderNum  
          }" @click="submit">
          {{ $t("SIGN UP") }}
        </button>
      </div>
    </div>
    <!-- <footer class="flex-column center">
      <h2>{{ $t("CONTACT US") }}</h2>
      <p>
        {{
          $t(
            "If you have any questions. please send an email and our customer service representative will reply you as soon as possible!"
          )
        }}
      </p>
      <div class="email flex center">
        <img src="/email.png" alt="">
        <span>xxxx@xxx.com</span>
      </div>
    </footer> -->
  </div>
</template>
<script>
import { userFeedback, getInfo } from "@/api/index.js";
export default {
  data () {
    return {
      formData: {
        email: this.$route.query.email || "",
        userName: "",
        orderNum: "",
        phone: "",
        lang: navigator.language || "en"
      }
    };
  },
  methods: {
    async submit () {
      if (!this.formData.orderNum) {
        return alert(this.$t("Order No. cannot be blank"));
      }
      //       if (!this.formData.userName) {
      //         return alert(this.$t("Name cannot be empty"));
      //       }
      //       if (!this.formData.email) {
      //         return alert(this.$t("Email cannot be empty"));
      //       }
      //       if (!this.formData.phone) {
      //         return alert(this.$t("Phone cannot be empty"));
      //       }
      let res = await userFeedback(this.formData);
      console.log(res.code);
      if (res.code === 200) {
        this.$router.push({
          path: "/gift",
          query: { orderNum: this.formData.orderNum }
        });
      } else {
        return alert(this.$t(res.msg));
      }
    }
  },
  async created () {
    let aa = navigator.language || "en-US";
    aa = aa.slice(0, 2);
    if (aa === "zh") {
      return this.$router.push("/");
    }

    let d = await getInfo();
    console.log(d.msg);
    if (d.msg !== "ok") {
      this.$router.push("/");
    }
  },
  mounted () { }
};
</script>

<style lang="scss" scoped>
.form {
  .head {
    background: #926bea;
    background-position: center center;
    background-size: cover;
    padding: 30px;
    height: 220px;
    position: relative;
    .mask {
      // background: rgba(0, 0, 0, 0.5);
      z-index: 1;
      .logo {
        width: 160px;
      }
      p {
        color: #fff;
        font-size: 12px;
        margin-top: 0px;
        text-align: center;
        margin-right: 30px;
        margin-left: 30px;
      }
    }
    .select {
      position: absolute;
      right: 15px;
      top: 15px;
      z-index: 9999;
      color: #fff;
      select {
        border-radius: 40px;
        padding-left: 10px;
        border: none;
        color: #666;
        line-height: 26px;
        height: 26px;
      }
    }
  }
  .formData {
    max-width: 700px;
    margin: auto;
    padding: 10px;
    font-size: 14px;

    .item {
      flex: 1;
      padding: 0 20px;
      min-width: 320px;
      margin-bottom: 20px;
      box-sizing: border-box;
    }
    input {
      display: block;
      border: none;
      width: 100%;
      line-height: 30px;
      border-bottom: 1px solid #ddd;
      outline: none;
      padding-left: 15px;
      background-color: #986ae8;
    }
    .label {
      color: #333;
      span {
        color: red;
        margin-right: 5px;
      }
    }
    .item.orderNo {
      width: 100%;
      flex-shrink: 0;
      flex-grow: 0;
      font-weight: bold;
      // .label {
      //   color: #333;
      //   font-weight: bold;
      // }
    }
    .value {
      margin-bottom: 10px;
    }
    // .bottom-box {
    //   flex-wrap: wrap;
    // }

    h3 {
      font-size: 14px;
      margin: 20px 0;
      text-align: center;
    }
    p {
      color: #666;
      font-size: 13px;
    }
    button {
      background: #986ae8;
      border: none;
      outline: none;
      color: #fff;
      width: 150px;
      height: 40px;
      border-radius: 40px;
      margin-top: 30px;
      font-size: 20px;
    }
    button.disable {
      background: #999;
    }
  }
  footer {
    padding: 40px;
    font-size: 13px;
    color: #666;
    text-align: center;
    h2 {
      font-size: 18px;
      color: #333;
      margin-bottom: 20px;
    }
    .email {
      margin-top: 10px;
      img {
        width: 20px;
      }
    }
  }
}
</style>
